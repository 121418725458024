import React, { useEffect, useState } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import './App.css';

export default function App() {
  const [apiData, setApiData] = useState({ users: [] });

  useEffect(() => {
    fetch('https://sandbox-server-production.up.railway.app/api')
      .then((res) => res.json())
      .then((data) => {
        setApiData(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    if (apiData.users && apiData.users.length > 0) {
      console.log(apiData.users);
    }
  }, [apiData.users]);

  const { isLoading, error, data } = useVisitorData(
    { extendedResult: true },
    { immediate: true },
    { ignoreCache: true }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }

  return (
    <>
      {/* <div>
        {apiData.users && apiData.users.length > 0 ? (
          apiData.users.map((user, idx) => <h4 key={idx}>{user}</h4>)
        ) : (
          <h2>Loading...</h2>
        )}
      </div> */}
      <div className="vis-container">
        <h2>
          Welcome
          {data.visitorFound ? ' back' : ''}, <span>{data.visitorId}</span>!
        </h2>
        <p id="underline">Full visitor data:</p>
        <p>{error ? error.message : JSON.stringify(data, null, 2)}</p>
      </div>
    </>
  );
}
