// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
p,
pre {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

div {
  margin-top: 20px;
  font-size: clamp(10px, 3vw, 35px);
  font-weight: bold;
}

.vis-container {
  flex-direction: column;
  border: solid 2px red;
  margin: 5vw;
}

#underline {
  text-decoration: underline;
  color: green;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;EAGE,SAAS;EACT,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,iCAAiC;EACjC,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,0BAA0B;EAC1B,YAAY;AACd","sourcesContent":["body,\np,\npre {\n  margin: 0;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n}\n\ndiv {\n  margin-top: 20px;\n  font-size: clamp(10px, 3vw, 35px);\n  font-weight: bold;\n}\n\n.vis-container {\n  flex-direction: column;\n  border: solid 2px red;\n  margin: 5vw;\n}\n\n#underline {\n  text-decoration: underline;\n  color: green;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
